import blockedImage from '../Data/blocked.jpg'
import styles from '../Styles/blocked.module.css'
import { Link } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AppContext } from '../Context/AppContext'

const Blocked = () => {

    const { setLogged } = useContext(AppContext)

    useEffect(() => {
        setLogged(false)
        // eslint-disable-next-line
    }, [])

    return ( 
        <div className={styles.wrapper}>
            <img src={blockedImage} alt="" />
            <Link to='/'>Spróbuj się zalogować</Link>
        </div>
     );
}
 
export default Blocked;