import { createContext, useEffect, useState } from 'react';
import { fetchLink } from '../Components/Config'

export const AppContext = createContext();

const AppProvider = ({children}) => {

    const [logged, setLogged] = useState(false)
    const [numbers, setNumbers] = useState([])
    const [inGame, setInGame] = useState([])
    useEffect(() => {
        fetch(`${fetchLink}/getDrawNumbers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setNumbers(data)
        })
    }, [])

    useEffect(() => {
        if(numbers.length !== 0) {
            const numberArray = [];
            for(let i = 0; i < numbers.length; i++) {
                numberArray.push(numbers[i].id)
            }
            setInGame(numberArray)
        }
    }, [numbers])
    
    return ( 
        <AppContext.Provider value={{ logged, setLogged, numbers, inGame, setInGame }}>
            {children}
        </AppContext.Provider>
     );
}
 
export default AppProvider;