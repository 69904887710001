import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Auth from "./Auth";
import Blocked from "./Blocked";
import Losowanie from "./Losowanie";

const App = () => {

    return ( 
        <Router>
        <div className="wrapper">
            <Switch>
                <Route exact path='/'>
                    <Auth />
                </Route>
                <Route exact path='/losowanie'>
                    <Losowanie />
                </Route>
                <Route exact path='/zablokowano'>
                    <Blocked />
                </Route>
                <Route exact path='/*'><Redirect to='/' /></Route>
            </Switch>
        </div>
        </Router>
     );
}
 
export default App;