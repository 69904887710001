import { useContext, useState } from "react";
import { AppContext } from "../Context/AppContext";
import { Redirect } from 'react-router-dom'
import styles from '../Styles/losowanie.module.css'

import PersonIcon from '@mui/icons-material/Person';
import Fade from 'react-reveal/Fade';
import "@lottiefiles/lottie-player";
import winSound from '../Data/win.wav'
import Sound from 'react-sound';


const Losowanie = () => {

    const { logged, numbers, inGame, setInGame } = useContext(AppContext)
    /*let random = 1;
    useEffect(() => {
        random = Math.floor(Math.random() * 10000)
        for(let i = 0; i < 55; i++) {
            setTimeout(() => {
                console.log(i);
                setCurrentType(i)
            }, i*100)
        }
    }, [])*/

    const [firstWinner, setFirstWinner] = useState('')
    const [firstWinnerLoaded, setFirstWinnerLoaded] = useState(false)
    const [secoundWinner, setSecoundWinner] = useState('')
    const [secoundWinnerLoaded, setSecoundWinnerLoaded] = useState(false)
    const [thierdWinner, setThierdWinner] = useState('')
    const [thierdWinnerLoaded, setThierdWinnerLoaded] = useState(false)
    const [finishDraw, setFinishDraw] = useState(false)

    const [drawAnimation, setDrawAnimation] = useState(false)
    const [soundPlay, setSoundPlay] = useState(false)

    const handleDrawNumber = () => {
        const random = Math.floor(Math.random() * inGame.length)
        
        if(firstWinner === '') {
            setFirstWinner(inGame[random])
            setDrawAnimation(true)
            setTimeout(() => {
                setDrawAnimation(false)
                setFirstWinnerLoaded(true)
                setSoundPlay(true)
            }, 5000)
            setTimeout(() => {
                setSoundPlay(false)
            }, 3000)
        }   else if(secoundWinner === '') {
            setSecoundWinner(inGame[random])
            setDrawAnimation(true)
            setTimeout(() => {
                setDrawAnimation(false)
                setSecoundWinnerLoaded(true)
                setSoundPlay(true)
            }, 5000)
            setTimeout(() => {
                setSoundPlay(false)
            }, 3000)
        }   else if(thierdWinner === '') {
            setThierdWinner(inGame[random])
            setDrawAnimation(true)
            setTimeout(() => {
                setDrawAnimation(false)
                setThierdWinnerLoaded(true)
                setFinishDraw(true)
                setSoundPlay(true)
            }, 5000)
            setTimeout(() => {
                setSoundPlay(false)
            }, 3000)
        }
        let newArray = inGame;
        newArray.splice(random, 1);
        setInGame(newArray)
    }

    return ( 
        <>
            { logged ? <div className={styles.wrapper}>

                <div className={styles.nav} id={styles.noneShow}>
                    <div className={styles.box}>
                        <PersonIcon />
                        <p>{numbers.length}</p>
                    </div>
                </div>

                <div className={styles.content}>
                    <div className={styles.winners}>
                       <h2>Wygrane numery</h2>
                       <div className={styles.winnerNumbers}>
                            <div className={styles.winnerNumberShow}>
                                { !firstWinnerLoaded ? <>
                                <img src="https://img.icons8.com/emoji/48/000000/question-mark-emoji.png" alt='question mark icon'/>
                                </> : <> 
                                <img src="https://img.icons8.com/fluency/48/000000/crown.png" alt='crown icon'/>
                                <p>{firstWinner}</p></>}
                            </div>
                            <div className={styles.winnerNumberShow}>
                                { !secoundWinnerLoaded ? <>
                                <img src="https://img.icons8.com/emoji/48/000000/question-mark-emoji.png" alt='question mark icon'/>
                                </> : <> 
                                <img src="https://img.icons8.com/fluency/48/000000/crown.png" alt='crown icon'/>
                                <p>{secoundWinner}</p></>}
                            </div>
                            <div className={styles.winnerNumberShow}>
                                { !thierdWinnerLoaded ? <>
                                <img src="https://img.icons8.com/emoji/48/000000/question-mark-emoji.png" alt='question mark icon'/>
                                </> : <> 
                                <img src="https://img.icons8.com/fluency/48/000000/crown.png" alt='crown icon'/>
                                <p>{thierdWinner}</p></>}
                            </div>
                       </div>
                    </div>
                    { drawAnimation ? <div className={styles.drawAnimation}><lottie-player 
          autoplay
          loop
          mode="normal"
          src="https://assets7.lottiefiles.com/packages/lf20_zJSuaQ.json"
          style={{ width: "500px", height: "500px" }}
        ></lottie-player></div> : <>
                    <div className={styles.drawMachine}>
                        { finishDraw ? <><Fade>
                            <h2>Losowanie zostało zakończone</h2>
                            <p>Ze zwycięzcami konkursy skontaktujemy się telefonicznie, oraz ustalimy termin odebrania nagrody. Gratulujemy!</p>
                            </Fade></> : null }
                        { finishDraw ? null : <><h2>Numery w losowaniu</h2>
                        <div className={styles.numberList}>
                            { numbers && numbers.map((number, i) => <DisplayDrawNumber key={i}  index={i} data={number.id} firstWinner={firstWinner} secoundWinner={secoundWinner} thierdWinner={thierdWinner} firstWinnerLoaded={firstWinnerLoaded} secoundWinnerLoaded={secoundWinnerLoaded} thierdWinnerLoaded={thierdWinnerLoaded}/>) }
                        </div></> }
                    </div> 
                    { finishDraw ? null : 
                    <div className={styles.drawButtonWrapper}>
                        <button onClick={handleDrawNumber}>Losuj numer</button>
                    </div> } </>}
                    { soundPlay ? <Sound url={winSound} playStatus={Sound.status.PLAYING}/> : null }
                </div>

            </div> : <Redirect to='/zablokowano' /> }
        </>
     );
}
 
export default Losowanie;

const DisplayDrawNumber = ({ data, firstWinner, secoundWinner, thierdWinner, firstWinnerLoaded, secoundWinnerLoaded, thierdWinnerLoaded }) => {

    return ( 
        <div className={styles.numberBox} id={data === firstWinner || data === secoundWinner || thierdWinner === data ? styles.checked : null}>
           { data }
           { /* eslint-disable-next-line*/ }
           { firstWinnerLoaded && firstWinner===data || secoundWinnerLoaded && secoundWinner===data || thierdWinnerLoaded && thierdWinner===data  ? 
           <div className={styles.winnerInNumbers}>
                <img src="https://img.icons8.com/fluency/48/000000/crown.png" alt='crown icon'/>
           </div> : null }
        </div>
     );
}