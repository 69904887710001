import styles from '../Styles/auth.module.css'
import { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { fetchLink } from './Config'
import { AppContext } from '../Context/AppContext'

const Auth = () => {

    const { setLogged, logged } = useContext(AppContext)

    const [protoCode, setProtoCode] = useState('')
    const handleSetProtoCode = (e) => {
        const value = e.target.value;
        setProtoCode(value)
    }

    const handleSubmitCode = (e) => {
        e.preventDefault();
        const codeObject = {
            protoCode
        }

        fetch(`${fetchLink}/checkCode`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(codeObject)
        })
        .then(response => response.json())
        .then(data => {
            setLogged(data.authResponse)
            // eslint-disable-next-line
            if(data.authResponse == false) {
                setProtoCode('')
                setLogged('redirect')
            }
        })
    }

    return ( 
        <>
        { logged ? <Redirect to='/losowanie' /> :   
        <div className={styles.wrapper}>
            <form className={styles.form} onSubmit={handleSubmitCode}>
                <h2>Podaj kod dostępu</h2>
                <input type="password" value={protoCode} onChange={handleSetProtoCode}/>
                <button>Uzyskaj dostęp</button>
            </form>
        </div> }
        { logged === 'redirect' ? <Redirect to='/zablokowano' /> : null }
        </>
     );
}
 
export default Auth;